// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-convention-2019-follow-the-debates-js": () => import("./../../../src/pages/archive/convention-2019/follow-the-debates.js" /* webpackChunkName: "component---src-pages-archive-convention-2019-follow-the-debates-js" */),
  "component---src-pages-archive-convention-2019-npc-slate-js": () => import("./../../../src/pages/archive/convention-2019/npc-slate.js" /* webpackChunkName: "component---src-pages-archive-convention-2019-npc-slate-js" */),
  "component---src-pages-archive-convention-2019-our-endorsements-js": () => import("./../../../src/pages/archive/convention-2019/our-endorsements.js" /* webpackChunkName: "component---src-pages-archive-convention-2019-our-endorsements-js" */),
  "component---src-pages-archive-convention-2019-our-proposals-js": () => import("./../../../src/pages/archive/convention-2019/our-proposals.js" /* webpackChunkName: "component---src-pages-archive-convention-2019-our-proposals-js" */),
  "component---src-pages-archive-convention-2021-overview-js": () => import("./../../../src/pages/archive/convention-2021/overview.js" /* webpackChunkName: "component---src-pages-archive-convention-2021-overview-js" */),
  "component---src-pages-archive-convention-2021-resolutions-js": () => import("./../../../src/pages/archive/convention-2021/resolutions.js" /* webpackChunkName: "component---src-pages-archive-convention-2021-resolutions-js" */),
  "component---src-pages-archive-convention-2021-slate-js": () => import("./../../../src/pages/archive/convention-2021/slate.js" /* webpackChunkName: "component---src-pages-archive-convention-2021-slate-js" */),
  "component---src-pages-archive-convention-2021-vision-js": () => import("./../../../src/pages/archive/convention-2021/vision.js" /* webpackChunkName: "component---src-pages-archive-convention-2021-vision-js" */),
  "component---src-pages-archive-convention-2023-js": () => import("./../../../src/pages/archive/convention-2023.js" /* webpackChunkName: "component---src-pages-archive-convention-2023-js" */),
  "component---src-pages-archive-dslc-slate-2020-js": () => import("./../../../src/pages/archive/dslc-slate-2020.js" /* webpackChunkName: "component---src-pages-archive-dslc-slate-2020-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-archive-tasks-2019-js": () => import("./../../../src/pages/archive/tasks-2019.js" /* webpackChunkName: "component---src-pages-archive-tasks-2019-js" */),
  "component---src-pages-dsa-elections-2023-js": () => import("./../../../src/pages/dsa-elections-2023.js" /* webpackChunkName: "component---src-pages-dsa-elections-2023-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-reading-list-js": () => import("./../../../src/pages/reading-list.js" /* webpackChunkName: "component---src-pages-reading-list-js" */),
  "component---src-pages-tasks-2022-23-js": () => import("./../../../src/pages/tasks-2022-23.js" /* webpackChunkName: "component---src-pages-tasks-2022-23-js" */),
  "component---src-pages-where-we-stand-js": () => import("./../../../src/pages/where-we-stand.js" /* webpackChunkName: "component---src-pages-where-we-stand-js" */)
}

